import { t } from 'helpers'
import s from './index.module.scss'
import classNames from 'classnames'
import Images from './images'
import { useParams } from 'react-router-dom'
import Card from './Card'
import { HOME_PAGES } from 'types/enums'

const GridLayout = (): JSX.Element => {
	const { role = 'landing' } = useParams()
	if (role === HOME_PAGES.ACTOR) return <></>

	const key = role?.toUpperCase()
	const title = `APP_${key}_CAROUSEL_TITLE`
	const description = `APP_${key}_CAROUSEL_DESCRIPTION`

	const cards = Array.from({ length: role === 'landing' ? 4 : 5 }).map(
		(_, index) => ({
			title: `APP_${key}_CARD${index + 1}_TITLE`,
			image: (Images as { [key: string]: string })[`${role}${index + 1}`],
			style:
				role === 'landing'
					? {
							maxHeight: index > 0 ? '273px' : 'unset',
							minHeight: index > 0 ? '273px' : 'unset',
					  }
					: {},
		}),
	)

	return (
		<div className={s.itemWrapper}>
			<div className={s.titles}>
				<h3 style={{ fontWeight: '500' }}>{t(title)}</h3>
				<span style={{ color: 'var(--mono300)', fontWeight: '500' }}>
					{t(description)}
				</span>
			</div>
			<div className={classNames(s.itemsLayout, s['itemsLayout-' + role])}>
				{cards?.map((card) => (
					<Card key={card.title} card={card} type={role} />
				))}
			</div>
		</div>
	)
}

export default GridLayout

import { useLocation, useNavigate } from 'react-router-dom'
import TabsWrapper from '../TabsWrapper'
import Tab from '../Tab'
import { EventBus } from 'helpers/EventBus'
import { HOME_PAGES, PATHS } from 'types/enums'

const tabs = [
	{
		label: 'APP_HOME_TAB',
		key: '/' + PATHS.HOME,
	},
	{
		label: 'APP_ACTORS_TAB',
		key: '/' + PATHS.HOME + '/' + HOME_PAGES.ACTOR,
	},
	{
		label: 'APP_CASTINGDIRECTORS_TAB',
		key: '/' + PATHS.HOME + '/' + HOME_PAGES.CD,
	},
	{
		label: 'APP_AGENCIES_TAB',
		key: '/' + PATHS.HOME + '/' + HOME_PAGES.AGENCY,
	},
]

interface Props {
	classname?: string
}

const HomeTabs = ({ classname }: Props): JSX.Element => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	return (
		<TabsWrapper classname={classname}>
			<>
				{tabs?.map((tab) => (
					<Tab
						key={tab.key}
						tabName={tab.label}
						onClick={() => {
							EventBus.$emit('closeHomeMenu')
							navigate(tab.key)
						}}
						active={pathname === tab.key}
					/>
				))}
			</>
		</TabsWrapper>
	)
}

export default HomeTabs

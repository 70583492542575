import actor1 from 'assets/images/hero-actor-1.png'
import actor2 from 'assets/images/hero-actor-2.png'
import actor3 from 'assets/images/hero-actor-3.png'
import agency1 from 'assets/images/hero-agency-1.png'
import agency2 from 'assets/images/hero-agency-2.png'
import agency3 from 'assets/images/hero-agency-3.png'
import castingdirector1 from 'assets/images/hero-cd-1.png'
import castingdirector2 from 'assets/images/hero-cd-2.png'
import castingdirector3 from 'assets/images/hero-cd-3.png'

export default {
	actor1,
	actor2,
	actor3,
	agency1,
	agency2,
	agency3,
	castingdirector1,
	castingdirector2,
	castingdirector3,
}

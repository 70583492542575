export enum PATHS {
	HOME = 'home',
	DASHBOARD = 'dashboard',
	ACTOR = 'actor',
	// CONTRIBUTOR = 'contributor',
	CASTING_DIRECTOR = 'castingdirector',
	AGENCY = 'agency',
	PROFILE = 'user/:userId',
	JOIN = 'join',
	LOGIN = 'login',
	ACCEPT = 'accept',
	ONBOARDING = 'onboarding',
	PORTFOLIO = 'portfolio',
	SHOWREELS = 'showreels',
	VALIDATION = 'validation',
	NETWORK = 'network',
	CASTING = 'casting',
	PROJECT = 'project',
	LEGAL = 'legal',
	PRIVACY = 'privacy',
	TERMS = 'terms',
	COOKIES = 'cookies',
	LEGAL_NOTICE = 'legal-notice',
	ABOUT = 'about-us',
	CONTACT = 'contact-us',
	SETTINGS = 'settings',
	TIPS = 'tips',
	USER_ROLE = ':userRole',
	MESSAGE = 'message',
	NOT_FOUND = '*',
	CASTING_UPLOAD = 'casting-upload',
	OVERVIEW = 'overview',
	PUBLIC = 'public',
}

export enum DROPDOWN_OPTIONS {
	PROFESSION = 'APP_DROPDOWN_PROFESSION',
	LANGUAGE = 'APP_DROPDOWN_LANGUAGES',
	GENDERS = 'APP_CHECKBOX_GENDERS',
	SEX = 'APP_CHECKBOX_SEXUALITY',
	RACE = 'APP_CHECKBOX_RACES',
	DISABILITY = 'APP_CHECKBOX_DISABILITIES',
	NATION = 'APP_CHECKBOX_NATIONALITY',
	HAIR_TYPE = 'APP_CHECKBOX_HAIR_TYPES',
	HAIR_COLOR = 'APP_CHECKBOX_HAIR_COLORS',
	EYE_COLOR = 'APP_CHECKBOX_EYE_COLORS',
	BODY_TYPE = 'APP_CHECKBOX_BODYTYPES',
	BODY_MODIFY = 'APP_CHECKBOX_BODYMODIFICATON',
	SKIN_COLOR = 'APP_CHECKBOX_SKIN_COLORS',
	TATTOO = 'YES|Yes',
	TRAVEL_WILLINGNESS = 'YES|Yes\nNO|No',
	DRIVING_LICENSE = 'APP_DROPDOWN_DRIVING_LICENSE',
	PERSONAL_ABILITIES = 'APP_CHECKBOX_PERSONAL_ABILITIES',
	VOCAL_NO = "CANT_SING|Can't sing",
	VOCAL = 'APP_DROPDOWN_VOCAL',
	VOCAL_TECHNIQUE = 'APP_DROPDOWN_VOCAL_TECHNIQUE',
	VOCAL_SPEC = 'APP_CHECKBOX_VOCAL_SPECIALS',
	SPECIAL_ATTRIBUTES = 'APP_CHECKBOX_SPECIAL_ATTRIBUTES',
	SPORTS = 'APP_CHECKBOX_SPORTS',
	LANGUAGE_LEVELS = 'APP_CHECKBOX_LANGUAGE_LEVELS',
	SPECIAL_SPORT = 'APP_CHECKBOX_SPECIALSPORT',
	LANGUAGES_CHECKBOX = 'APP_CHECKBOX_LANGUAGES',
	DIALECTS = 'APP_CHECKBOX_DIALECTS',
	MUSIC_GENRE = 'APP_CHECKBOX_MUSICGENRE',
	SKILL_LEVELS = 'APP_CHECKBOX_SKILL_LEVELS',
	DANCE_STYLES = 'APP_CHECKBOX_DANCESTYLES',
	INSTRUMENTS = 'APP_DROPDOWN_INSTRUMENTS',
	OTHER_SKILLS = 'APP_CHECKBOX_SKILLS',
	SPEC_SKILLS = 'APP_CHECKBOX_SPECIAL_WILLING',
	PICTURE_TYPES = 'APP_CHECKBOX_PICTURETYPES',
	PICTURE_STYLES = 'APP_CHECKBOX_PICTURESTYLES',
	SHOWREEL_TYPES = 'APP_CHECKBOX_SHOWREELTYPES',
	SHOWREEL_STYLES = 'APP_CHECKBOX_SHOWREELSTYLES',
	ONBOARDING_QUESTIONS = 'APP_CHECKBOX_ONBOARDING_QUESTIONS',
	ONBOARDING_GOALS = 'APP_DROPDOWN_GOAL_REACH',
	TUTORIAL_AGENT = 'BRUCE|Bruce\nSCARLETT|Scarlett',
	PRODUCTION_TYPES = 'APP_DROPDOWN_PRODUCTION_TYPES',
	ROLE_TYPE = 'Actor|Actor\nAgency|Agency',
	ROLE_STATUS = 'Lead|APP_LEAD_LABEL\nSupporting|APP_SUPPORTING_LABEL\nStatist|APP_STATIST_LABEL',
	ALLOW_FREE_WORK_PORTFOLIO = '1|Yes\n0|No',
	MEDIA_PINNED = '1|1\n2|2\n3|3',
}

export enum SORT_DIRECTION {
	ASC = 'Asc',
	DESC = 'Desc',
	RANDOM = 'Randomize',
}

export enum SORT_FORMAT {
	TEXT = 'Text',
	BOOLEAN = 'Boolean',
	DATETIME = 'DateTime',
	NUMERIC = 'Numeric',
	LIST = 'List',
	OBJECT = 'Object',
}

export enum FILTER_CONDITION {
	EQUAL = 'Equal',
	NOT_EQUAL = 'NotEqual',
	GREATER = 'Greater',
	GREATER_OR_EQUAL = 'GreaterOrEqual',
	LESS = 'Less',
	LESS_OR_EQUAL = 'LessOrEqual',
	CONTAINS = 'Contains',
	NOT_CONTAINS = 'NotContains',
	EXISTS = 'Exists',
	NOT_EXISTS = 'NotExists',
}

export enum RELATIONS {
	IMAGE = 'Image',
	BEST_PERFORMANCES = 'BestPerformance',
	CHAT = 'Chat',
	SIDESHOW = 'Sideshow',
	PROJECT = 'Project',
	CREATOR = 'Creator',
	ORGANIZATION = 'Organization',
	PLAYLIST = 'Playlist',
	FAVCONTENT = 'FavContent',
	FAVCOLLECTION = 'FavCollection',
	FAVPLAYLIST = 'FavPlaylist',
	FAVCUSTOMER = 'FavCustomer',
	ACCESS = 'Access',
	COLLECTION = 'Collection',
	RELATIONSHIP = 'Relationship',
	CONTENT = 'Content',
	REFERENCE = 'Reference',
	CUSTOMER_INVITE = 'CustomerInvite',
	GROUP_INVITE = 'GroupInvite',
	INVITE = 'Invite',
	AGENCY = 'Company',
}

export enum PORTFOLIO_TYPES {
	ATTRIBUTE = 'attribute',
	SKILL = 'skill',
	PICTURE = 'picture',
	SHOWREEL = 'showreel',
}

export enum CASTING_STATUS {
	INVITED = 'Invited',
	REJECTED = 'Rejected',
	WAITING = 'WaitingForApproval',
	READY = 'ReadyToApply',
	UPLOADING = 'Uploading',
	APPLIED = 'Applied',
	LIKED = 'Liked',
	DISLIKED = 'Disliked',
	FAVORITE = 'Favorite',
	NEUTRAL = 'Neutral',
	SELECTED = 'Selected',
	DECLINED = 'Declined',
	REAPPLIED = 'Reapplied',
	RESUBMITED = 'Resubmited',
}

export const PLAYER_SPEED = [0.5, 1, 1.5, 2]

export enum SIDEBARS {
	NOTIFICATIONS = 'notifications',
	ACCEPT_CASTING_INVITE = 'acceptinvite',
}

export enum ROLE_STATUS {
	WORKING = 'Working',
	CASTING = 'Casting',
	COMPLETED = 'Completed',
	CLOSED = 'Closed',
	OPENED = 'Opened',
}

export enum MESSAGE_TYPE {
	CASTING = 'CastingInvite',
	CUSTOMER = 'CustomerInvite',
	NOTIFY_WINNER = 'CustomerWinner',
	NOTIFY_PENDING = 'CustomerPending',
}

export enum PROJECT_ACCESS {
	READ = 'Read', // (Producers),
	EDIT = 'Edit', // (Directors),
	FULLACCESS = 'Full Access', // (Assistance)
}

export enum HOME_SECTIONS {
	HERO = 'hero',
	CAROUSEL_ACTORS = 'carousel-actors',
	CAROUSEL_AGENCIES = 'carousel-agencies',
	CAROUSEL_CASTINGDIRECTOR = 'carousel-cd',
	HOW_IT_WORKS = 'how-it-works',
}

export enum HOME_PAGES {
	ACTOR = 'actor',
	AGENCY = 'agency',
	CD = 'castingdirector',
}

export enum DASHBOARD_SECTIONS {
	PERSONAL = 'personal',
	CASTING = 'casting',
	NETWORK = 'network',
}

export enum PROFILE_VALIDATION_STATUS {
	OPENED = 'Opened',
	STARTED = 'Started',
	INPROGRESS = 'In-Progress',
	APPROVED = 'Approved',
	DECLINED = 'Declined',
}

export enum FEEDBACKS {
	FEEDBACK = 'FEEDBACK',
	PROFILE_REPORT = 'PROFILE_REPORT',
}

export enum PROFILE_READINESS {
	LOW = 0,
	MEDIUM = 60,
	HIGH = 90,
}

export enum UPDATE_STRATEGY {
	OVERWRITE = 0,
	ADD = 1,
	REMOVE = 2,
}

import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import classNames from 'classnames'
import Shadow1 from 'assets/images/landing-hero-shadow1.png'
import Shadow2 from 'assets/images/landing-hero-shadow2.png'
import Shadow3 from 'assets/images/landing-hero-shadow3.png'
import Image1 from 'assets/images/hero-landing-1.png'
import Image2 from 'assets/images/hero-landing-2.png'
import Image3 from 'assets/images/hero-landing-3.png'
import { ArrowShortIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t, textParser } from 'helpers'
import { HOME_SECTIONS, PATHS } from 'types/enums'
import s from './index.module.scss'
import style from '../index.module.scss'

const items = [
	{
		id: 1,
		tag: 'APP_HERO_LANDING_CAROUSEL_TAG_ACTOR',
		title: 'APP_HERO_LANDING_CAROUSEL_TITLE_ACTOR',
		desc: 'APP_HERO_LANDING_CAROUSEL_DESC_ACTOR',
		image: Image1,
		shadow: Shadow1,
	},
	{
		id: 2,
		tag: 'APP_HERO_LANDING_CAROUSEL_TAG_CD',
		title: 'APP_HERO_LANDING_CAROUSEL_TITLE_CD',
		desc: 'APP_HERO_LANDING_CAROUSEL_DESC_CD',
		image: Image2,
		shadow: Shadow2,
	},
	{
		id: 3,
		tag: 'APP_HERO_LANDING_CAROUSEL_TAG_AGENCY',
		title: 'APP_HERO_LANDING_CAROUSEL_TITLE_AGENCY',
		desc: 'APP_HERO_LANDING_CAROUSEL_DESC_AGENCY',
		image: Image3,
		shadow: Shadow3,
	},
	{
		id: 4,
		tag: 'APP_HERO_LANDING_CAROUSEL_TAG_ACTOR',
		title: 'APP_HERO_LANDING_CAROUSEL_TITLE_ACTOR',
		desc: 'APP_HERO_LANDING_CAROUSEL_DESC_ACTOR',
		image: Image1,
		shadow: Shadow1,
	},
	{
		id: 5,
		tag: 'APP_HERO_LANDING_CAROUSEL_TAG_CD',
		title: 'APP_HERO_LANDING_CAROUSEL_TITLE_CD',
		desc: 'APP_HERO_LANDING_CAROUSEL_DESC_CD',
		image: Image2,
		shadow: Shadow2,
	},
	{
		id: 6,
		tag: 'APP_HERO_LANDING_CAROUSEL_TAG_AGENCY',
		title: 'APP_HERO_LANDING_CAROUSEL_TITLE_AGENCY',
		desc: 'APP_HERO_LANDING_CAROUSEL_DESC_AGENCY',
		image: Image3,
		shadow: Shadow3,
	},
]

const LandingHero = (): JSX.Element => {
	const navigate = useNavigate()
	const [activeSlide, setActiveSlide] = useState(0)
	const progress = useRef<HTMLDivElement>(null)

	return (
		<>
			<img
				className={s.shadow}
				src={items[activeSlide].shadow}
				alt="shadow-background"
			/>
			<div className={s.wrapper}>
				<div className={s.titles}>
					<h1>{t('APP_HOME_HERO_LANDING_TITLE')}</h1>
					<h3>{t('APP_HOME_HERO_LANDING_DESCRIPTION')}</h3>
				</div>
				<div className={s.carouselWrapper}>
					<button
						id="home-hero-carousel-prev"
						className={classNames(style.arrowLeft, style.arrow)}>
						<ArrowShortIcon style={{ transform: 'rotate(180deg) ' }} />
					</button>
					<button
						id="home-hero-carousel-next"
						className={classNames(style.arrowRight, style.arrow)}>
						<ArrowShortIcon />
					</button>
					<Swiper
						navigation={{
							nextEl: '#home-hero-carousel-next',
							prevEl: '#home-hero-carousel-prev',
						}}
						onSlideChange={(swiper: SwiperClass) => {
							setActiveSlide(swiper.realIndex)
							if (progress.current) {
								const el = progress.current as HTMLDivElement
								el.classList.remove(s.progressAnimation)
								setTimeout(() => {
									el.classList.add(s.progressAnimation)
								}, 1000)
							}
						}}
						onSwiper={(swiper: SwiperClass) => {
							setTimeout(() => {
								swiper.autoplay.start()
							}, 300)
							setTimeout(() => {
								if (progress.current) {
									const el = progress.current as HTMLDivElement
									el.classList.add(s.progressAnimation)
								}
							}, 300)
						}}
						autoplay={{
							delay: 10000,
							disableOnInteraction: false,
						}}
						spaceBetween={10}
						modules={[Navigation, Autoplay]}
						loop
						speed={1000}
						slidesPerView={1}
						style={{
							maxWidth: '100%',
						}}>
						{items?.map((item, index) => (
							<SwiperSlide data-index={index} key={item.id}>
								<div className={s.itemWrapper}>
									<img src={item.image} alt="carousel-image" />
									<div className={s.info}>
										<div className={s.tag}>
											<span className="body2-b">{t(item.tag)}</span>
										</div>
										{textParser(t(item.title)).map((text) => (
											<h2 key={text}>{text}</h2>
										))}
										<span>{t(item.desc)}</span>
									</div>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
					<div ref={progress} className={s.progress} />
				</div>
				<div className={s.buttons}>
					<Button
						onClick={() => {
							navigate(`/${PATHS.JOIN}/${PATHS.LOGIN}`)
						}}
						variant="outlined"
						style={{
							gap: '10px',
							color: 'var(--mono900)',
							borderColor: 'var(--theme-primary)',
							backgroundColor: 'var(--theme-primary)',
						}}>
						{t('APP_JOINFREE_LABEL')}
					</Button>
					<Button
						onClick={() => {
							const section = document.getElementById(
								HOME_SECTIONS.HOW_IT_WORKS,
							)
							section?.scrollIntoView({ behavior: 'smooth' })
						}}
						variant="outlined"
						style={{
							gap: '10px',
							color: 'var(--theme-primary)',
							borderColor: 'var(--theme-primary)',
						}}>
						{t('APP_PLAYVIDEO_LABEL')}
					</Button>
				</div>
			</div>
		</>
	)
}

export default LandingHero

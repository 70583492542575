import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import Shadow1 from 'assets/images/home-actor-section2-blur.png'
import Shadow2 from 'assets/images/home-actor-section3-blur.png'
import Shadow3 from 'assets/images/home-actor-section4-blur.png'
import Shadow4 from 'assets/images/home-actor-section5blur.png'
import Image1 from 'assets/images/home-actor-section-2.png'
import Image2 from 'assets/images/home-actor-section-3.png'
import Image3 from 'assets/images/home-actor-section-4.png'
import Image4 from 'assets/images/home-actor-section-5.png'
import Star from 'assets/images/star.png'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { PATHS } from 'types/enums'
import s from './index.module.scss'
import { ArrowLongIcon } from 'assets/icons'

const ActorMiddleSection = (): JSX.Element => {
	const navigate = useNavigate()

	const sections = [
		{
			title: 'APP_HOME_ACTOR_SECTION2_TITLE',
			desc: 'APP_HOME_ACTOR_SECTION2_DESCRIPTION',
			image: Image1,
			shadow: Shadow1,
		},
		{
			title: 'APP_HOME_ACTOR_SECTION3_TITLE',
			desc: 'APP_HOME_ACTOR_SECTION3_DESCRIPTION',
			image: Image2,
			shadow: Shadow2,
		},
		{
			title: 'APP_HOME_ACTOR_SECTION4_TITLE',
			desc: 'APP_HOME_ACTOR_SECTION4_DESCRIPTION',
			image: Image3,
			shadow: Shadow3,
		},
		{
			title: 'APP_HOME_ACTOR_SECTION5_TITLE',
			desc: 'APP_HOME_ACTOR_SECTION5_DESCRIPTION',
			image: Image4,
			shadow: Shadow4,
		},
		{
			title: 'APP_HOME_ACTOR_SECTION6_TITLE',
			desc: 'APP_HOME_ACTOR_SECTION6_DESCRIPTION',
			image: Star,
		},
	]

	return (
		<section className={s.container}>
			{sections?.slice(0, 2)?.map((section, index) => (
				<div className={s.wrapper} key={section.title}>
					<h2>{t(section.title)}</h2>
					<span style={{ color: 'var(--mono300)' }}>{t(section.desc)}</span>
					<img className={s.image} src={section.image} alt="actor-section" />
					<img className={s.shadow} src={section.shadow} alt="shadow" />
					{index === 0 ? (
						<div
							className={s.nextSection}
							onClick={() => {
								window.scrollTo({
									top: window.scrollY + window.innerHeight - 50,
									behavior: 'smooth',
								})
							}}>
							<span className="caption-m" style={{ color: 'var(--mono200)' }}>
								{t('APP_SCROLL_NEXT_SECTION')}
							</span>
							<div style={{ display: 'flex' }}>
								<ArrowLongIcon
									style={{
										transform: 'rotate(90deg) translateX(20px)',
									}}
									color="var(--mono200)"
									strokeWidth="1"
									width="55"
									height="100%"
								/>
							</div>
						</div>
					) : null}
				</div>
			))}
			<div className={s.wrapper}>
				<div className={s.section3Content}>
					<h2>{t(sections?.[2]?.title)}</h2>
					<span style={{ color: 'var(--mono300)' }}>
						{t(sections?.[2]?.desc)}
					</span>
				</div>
				<img
					src={sections?.[2]?.image}
					alt="actor-section"
					className={s.image}
				/>
				<img className={s.shadow} src={sections?.[2]?.shadow} alt="shadow" />
			</div>
			<div className={classNames(s.wrapper, s.section4Wrapper)}>
				{/* <img
					src={sections?.[3]?.shadow}
					alt="shadow"
					style={{
						position: 'absolute',
						width: '100%',
						top: '0',
						height: '100%',
					}}
				/> */}
				<div className={s.imageWrapper}>
					<img className={s.image} src={sections?.[3]?.image} />
				</div>
				<div className={s.section4Content}>
					<h2>{t(sections?.[3]?.title)}</h2>
					<span style={{ color: 'var(--mono300)' }}>
						{t(sections?.[3]?.desc)}
					</span>
				</div>
			</div>
			<div
				className={s.wrapper}
				style={{
					alignItems: 'center',
					maxWidth: '800px',
					margin: 'auto',
					padding: '0 20px',
					boxSizing: 'border-box',
				}}>
				<img className={s.image} src={sections?.[4]?.image} alt="star" />
				<h2>{t(sections?.[4]?.title)}</h2>
				<span style={{ color: 'var(--mono300)' }}>
					{t(sections?.[4]?.desc)}
				</span>
				<div style={{ margin: 'auto', marginTop: '20px' }}>
					<Button
						onClick={() => {
							navigate(`/${PATHS.JOIN}/${PATHS.LOGIN}`)
						}}
						variant="outlined"
						style={{
							gap: '10px',
							color: 'var(--mono900)',
							borderColor: 'var(--theme-primary)',
							backgroundColor: 'var(--theme-primary)',
						}}>
						{t('APP_JOINFREE_LABEL')}
					</Button>
				</div>
			</div>
		</section>
	)
}

export default ActorMiddleSection

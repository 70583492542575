import { CSSProperties } from 'react'
import cn from 'classnames'
import { t, textParser } from 'helpers'
import s from './index.module.scss'

interface Props {
	card: {
		title: string
		image: string
		style: CSSProperties
	}
	type?: string
}

const Card = ({ card, type }: Props): JSX.Element => {
	return (
		<div className={cn(s.item, s['item_' + type])} style={card.style}>
			<div className={s.content}>
				{textParser(t(card.title)).map((text) => (
					<h3 key={text}>{text}</h3>
				))}
				{/* <div className={s.backgroundShadow} /> */}
			</div>
			<img src={card.image} alt="home-carousel" />
		</div>
	)
}

export default Card

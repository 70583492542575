import castingdirector1 from 'assets/images/home-cd-1.png'
import castingdirector2 from 'assets/images/home-cd-2.png'
import castingdirector3 from 'assets/images/home-cd-3.png'
import castingdirector4 from 'assets/images/home-cd-4.png'
import castingdirector5 from 'assets/images/home-cd-5.png'
import agency1 from 'assets/images/home-ag-1.png'
import agency2 from 'assets/images/home-ag-2.png'
import agency3 from 'assets/images/home-ag-3.png'
import agency4 from 'assets/images/home-ag-4.png'
import agency5 from 'assets/images/home-ag-5.png'
import landing1 from 'assets/images/home-landing-1.png'
import landing2 from 'assets/images/home-landing-2.png'
import landing3 from 'assets/images/home-cd-2.png'
import landing4 from 'assets/images/home-cd-4.png'

export default {
	castingdirector1,
	castingdirector2,
	castingdirector3,
	castingdirector4,
	castingdirector5,
	agency1,
	agency2,
	agency3,
	agency4,
	agency5,
	landing1,
	landing2,
	landing3,
	landing4,
}

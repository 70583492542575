export const ArrowLongIcon = ({
	color = 'var(--mono100)',
	width = '68',
	height = '24',
	strokeWidth = '2',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 68 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M7 12H61M61 12L55.5 6.5M61 12L55.5 17.5"
			stroke={color}
			strokeWidth={strokeWidth}
		/>
	</svg>
)

export const ArrowFilledIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '25',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M18.9727 12.25C18.9727 12.5781 18.7539 12.9062 18.4258 13.043L6.20312 18.3203C6.09375 18.375 5.98438 18.375 5.875 18.375C5.62891 18.375 5.38281 18.293 5.21875 18.1016C4.97266 17.8555 4.91797 17.4453 5.08203 17.1172L7.10547 13.0977L13.7227 12.2773L7.10547 11.4297L5.08203 7.41016C4.91797 7.08203 4.97266 6.67188 5.21875 6.42578C5.46484 6.15234 5.875 6.07031 6.20312 6.20703L18.4531 11.457C18.7812 11.5938 18.9727 11.9219 18.9727 12.25Z"
			fill={color}
		/>
	</svg>
)

export const ArrowShortIcon = ({
	color = 'var(--mono100)',
	width = '24',
	height = '24',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M10.75 17.875C10.5039 17.875 10.2852 17.793 10.1211 17.6289C9.76562 17.3008 9.76562 16.7266 10.1211 16.3984L14.7422 11.75L10.1211 7.12891C9.76562 6.80078 9.76562 6.22656 10.1211 5.89844C10.4492 5.54297 11.0234 5.54297 11.3516 5.89844L16.6016 11.1484C16.957 11.4766 16.957 12.0508 16.6016 12.3789L11.3516 17.6289C11.1875 17.793 10.9688 17.875 10.75 17.875Z"
			fill={color}
		/>
	</svg>
)

export const ArrowSmallIcon = ({
	color = 'var(--mono100)',
	width = '14',
	height = '15',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<g clipPath="url(#clip0_3210_670)">
			<path
				d="M9.95312 7.20312C9.875 7.39062 9.6875 7.5 9.5 7.5H8V10.5C8 10.7812 7.76562 11 7.5 11H6.5C6.21875 11 6 10.7812 6 10.5V7.5H4.5C4.29688 7.5 4.10938 7.39062 4.03125 7.20312C3.95312 7.01562 4 6.79688 4.14062 6.65625L6.625 4.15625C6.73438 4.0625 6.85938 4 7 4C7.125 4 7.25 4.0625 7.34375 4.15625L9.84375 6.65625C9.98438 6.79688 10.0312 7.01562 9.95312 7.20312Z"
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id="clip0_3210_670">
				<rect
					width="14"
					height="14"
					fill="white"
					transform="translate(0 0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export const ArrowRoundedIcon = ({
	color = 'var(--mono100)',
	width = '32',
	height = '32',
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke={color} />
		<path
			d="M10.9141 14.6562L15.5078 9.84375C15.6445 9.70703 15.8086 9.65234 16 9.65234C16.1641 9.65234 16.3281 9.70703 16.4648 9.84375L21.0586 14.6562C21.3047 14.9297 21.3047 15.3398 21.0312 15.5859C20.7852 15.832 20.3477 15.832 20.1016 15.5586L16.6562 11.9219V21.2188C16.6562 21.6016 16.3555 21.875 16 21.875C15.6719 21.875 15.3438 21.6016 15.3438 21.2188V11.9219L11.8711 15.5586C11.625 15.832 11.1875 15.832 10.9414 15.5859C10.668 15.3398 10.668 14.9023 10.9141 14.6562Z"
			fill={color}
		/>
	</svg>
)

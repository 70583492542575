import { useParams } from 'react-router-dom'
import s from './index.module.scss'
import LandingHero from './LandingHero'
import RoleHero from './RoleHero'

const HeroCarousel = (): JSX.Element => {
	const { role } = useParams()

	return (
		<div className={s.container}>{!role ? <LandingHero /> : <RoleHero />}</div>
	)
}

export default HeroCarousel

import { useEffect, useRef } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { EffectCreative, Navigation } from 'swiper/modules'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { ArrowLongIcon, ArrowShortIcon } from 'assets/icons'
import Button from 'components/UI/Button'
import { t } from 'helpers'
import { PATHS } from 'types/enums'
import { HOME_SECTIONS } from 'types/enums'
import style from '../index.module.scss'
import s from './index.module.scss'
import 'swiper/css/effect-creative'
import Images from './images'
// import ActorShadow from 'assets/images/hero-actor-blur.png'

const RoleHero = (): JSX.Element => {
	const navigate = useNavigate()
	const { role } = useParams()
	const key = role?.toUpperCase()
	const swiper = useRef<SwiperClass | null>(null)

	const baseItems = Array.from({ length: 3 }).map((_, index) => ({
		title: `APP_HERO_CAROUSEL_${key}${index + 1}_TITLE`,
		desc: `APP_HERO_CAROUSEL_${key}${index + 1}_DESCRIPTION`,
		image: (Images as { [key: string]: string })[`${role}${index + 1}`],
	}))

	const items = Array.from({ length: 3 })
		.map((_, index) =>
			baseItems.map((item, i) => ({
				...item,
				id: index * 3 + 1 + i,
			})),
		)
		.flat()

	useEffect(() => {
		swiper.current?.slideTo(0)
	}, [role])

	return (
		<section className={classNames(style.container, s.container)}>
			<div className={s.container}>
				<div className={s.wrapper}>
					<div className={s.contentWrapper}>
						<Swiper
							onSwiper={(e: SwiperClass) => (swiper.current = e)}
							navigation={{
								nextEl: `#home-hero-carousel-${role}-next`,
								prevEl: `#home-hero-carousel-${role}-prev`,
							}}
							creativeEffect={{
								prev: {
									shadow: true,
									origin: 'left center',
									translate: ['-5%', 0, -200],
									rotate: [0, 180, 0],
								},
								next: {
									origin: 'right center',
									translate: ['5%', 0, -200],
									rotate: [0, -100, 0],
								},
							}}
							effect="creative"
							spaceBetween={10}
							modules={[Navigation, EffectCreative]}
							loop
							slidesPerView={1}
							style={{
								maxWidth: '100%',
							}}>
							{items?.map((item, index) => (
								<SwiperSlide data-index={index} key={item.id}>
									<div className={s.itemsWrapper}>
										<div className={classNames(s.content, 'hideScrollBar')}>
											<h2>{t(item.title)}</h2>
											<span style={{ fontWeight: '500' }}>{t(item.desc)}</span>

											<div className={s.mobileImage}>
												<img src={item.image} alt="home top" />
											</div>
											<div className={s.buttons}>
												<Button
													onClick={() => {
														navigate(`/${PATHS.JOIN}/${PATHS.LOGIN}`)
													}}
													variant="outlined"
													style={{
														gap: '10px',
														color: 'var(--mono900)',
														borderColor: 'var(--theme-primary)',
														backgroundColor: 'var(--theme-primary)',
													}}>
													{t('APP_JOINFREE_LABEL')}
												</Button>
												<Button
													onClick={() => {
														const section = document.getElementById(
															HOME_SECTIONS.HOW_IT_WORKS,
														)
														section?.scrollIntoView({ behavior: 'smooth' })
													}}
													variant="outlined"
													style={{
														gap: '10px',
														color: 'var(--theme-primary)',
														borderColor: 'var(--theme-primary)',
													}}>
													{t('APP_PLAYVIDEO_LABEL')}
												</Button>
											</div>
										</div>
										<div className={s.desktopImage}>
											<button
												id={`home-hero-carousel-${role}-prev`}
												className={classNames(
													style.arrowLeft,
													style.arrow,
													s.arrowLeft,
												)}>
												<ArrowShortIcon
													style={{ transform: 'rotate(180deg) ' }}
												/>
											</button>
											<button
												id={`home-hero-carousel-${role}-next`}
												className={classNames(
													style.arrowRight,
													style.arrow,
													s.arrowRight,
												)}>
												<ArrowShortIcon />
											</button>
											<img src={item.image} alt="home top" />
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<div
						className={s.discover}
						onClick={() => {
							window.scrollTo({
								top: window.innerHeight - 150,
								behavior: 'smooth',
							})
						}}>
						<span className="caption-m" style={{ color: 'var(--mono200)' }}>
							{t('APP_DISCOVER_NEXT_SECTION')}
						</span>
						<div style={{ display: 'flex', height: '68px' }}>
							<ArrowLongIcon
								style={{
									transform: 'rotate(90deg) translateX(20px)',
								}}
								color="var(--mono200)"
								strokeWidth="1"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default RoleHero

import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { t } from 'helpers'
import s from './index.module.scss'

const AutoplayCarousel = (): JSX.Element => {
	const items = [
		'APP_AUTOPLAY_CAROUSEL_TEXT1',
		'APP_AUTOPLAY_CAROUSEL_TEXT2',
		'APP_AUTOPLAY_CAROUSEL_TEXT3',
		'APP_AUTOPLAY_CAROUSEL_TEXT4',
		'APP_AUTOPLAY_CAROUSEL_TEXT1',
		'APP_AUTOPLAY_CAROUSEL_TEXT2',
		'APP_AUTOPLAY_CAROUSEL_TEXT3',
		'APP_AUTOPLAY_CAROUSEL_TEXT4',
	]

	return (
		<section>
			<Swiper
				spaceBetween={24}
				modules={[Autoplay]}
				loop
				slidesPerView={1}
				allowTouchMove={false}
				breakpoints={{
					1350: {
						slidesPerView: 3.5,
					},
					1200: {
						slidesPerView: 3,
					},
					768: {
						slidesPerView: 2,
					},
				}}
				autoplay={{
					delay: 0,
					disableOnInteraction: false,
				}}
				className={s.autoplaySwiper}
				speed={7000}
				navigation={false}
				style={{
					maxWidth: '100%',
				}}>
				{items?.map((text, index) => (
					<SwiperSlide
						data-index={index}
						key={text + index}
						style={{ width: 'fit-content' }}>
						<div className={classNames(s.itemWrapper, s['item' + (index + 1)])}>
							<h4>{t(text)}</h4>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	)
}

export default AutoplayCarousel

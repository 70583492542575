import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import HowItWorks from './components/HowItWorks'
import Footer from './components/Footer'
import { HOME_PAGES } from 'types/enums'
import s from './index.module.scss'
// import ActorHome from './components/ActorHome'
import HeroCarousel from './components/HeroCarousel'
import GridLayout from './components/GridLayout'
import Ready from './components/Ready'
import Unlock from './components/Unlock'
import AllInOne from './components/AllInOne'
import ActorMiddleSection from './components/ActorMiddleSection'
import AutoplayCarousel from './components/AutoplayCarousel'

const Home = (): JSX.Element => {
	const { role } = useParams()

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [role])

	useEffect(() => {
		const root = document.getElementById('root')
		const app = root?.children?.[0] as HTMLDivElement
		if (app && window.innerWidth < 1024) {
			app.style.overflow = 'hidden'
		}

		return () => {
			const root = document.getElementById('root')
			const app = root?.children?.[0] as HTMLDivElement
			if (app) {
				app.style.overflow = 'initial'
			}
		}
	}, [])

	return (
		<div className={s.container}>
			<HeroCarousel />
			<GridLayout />
			{!role ? (
				<>
					<Unlock />
					<Ready />
					<AllInOne />
				</>
			) : null}
			{role === HOME_PAGES.ACTOR ? (
				<>
					<ActorMiddleSection />
					<AutoplayCarousel />
					<Ready />
					<Unlock />
				</>
			) : null}
			<HowItWorks />
			<Footer />
		</div>
	)
}

export default Home

import { useNavigate } from 'react-router-dom'
import { ArrowShortIcon } from 'assets/icons'
import Image1 from 'assets/images/allinone-actor.png'
import Image2 from 'assets/images/allinone-cd.png'
import Image3 from 'assets/images/allinone-agency.png'
import { t } from 'helpers'
import { HOME_PAGES, PATHS } from 'types/enums'
import s from './index.module.scss'

const AllInOne = (): JSX.Element => {
	const navigate = useNavigate()

	const items = [
		{
			image: Image1,
			label: t('APP_HOME_ALLINONE_ACTOR'),
			link: '/' + PATHS.HOME + '/' + HOME_PAGES.ACTOR,
		},
		{
			image: Image2,
			label: t('APP_HOME_ALLINONE_CD'),
			link: '/' + PATHS.HOME + '/' + HOME_PAGES.CD,
		},
		{
			image: Image3,
			label: t('APP_HOME_ALLINONE_AGENCY'),
			link: '/' + PATHS.HOME + '/' + HOME_PAGES.AGENCY,
		},
	]

	return (
		<section className={s.container}>
			<h2>{t('APP_HOME_ALLINONE_TITLE')}</h2>
			<div className={s.wrapper}>
				{items?.map((item) => (
					<div
						className={s.item}
						key={item.label}
						onClick={() => {
							navigate(item.link)
						}}>
						<img src={item.image} alt="allinone" />
						<div className={s.titles}>
							<span>{t('APP_HMOE_ALLINONE_SUBTITLE')}</span>
							<div>
								<div
									style={{
										backgroundColor: 'var(--elev300)',
										filter: 'blur(30px)',
										position: 'absolute',
										width: '100%',
										height: '100%',
										top: '0',
										left: '0',
										zIndex: -1,
									}}
								/>
								<h2>{item.label}</h2>
								<div style={{ display: 'flex' }}>
									<ArrowShortIcon width="20" height="20" />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	)
}

export default AllInOne
